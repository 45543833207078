import Currency, { SerializedCurrency } from 'models/currency';
import AccountBalance, { SerializedAccountBalance } from './acountBalance';

export interface SerializedChartOfAccounts {
    name: string;
    id: string;
    organisation_id: string;
    updated_at: string;
    created_at: string;
    children: Array<SerializedChartOfAccounts>;
    description: string | null;
    currency: SerializedCurrency;
    account_balance?: SerializedAccountBalance;
}

export default class ChartOfAccounts {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _organisationId: string;
    private readonly _children: Array<ChartOfAccounts>;
    private readonly _description: string | null;
    private readonly _currency: Currency;
    private readonly _updatedAt: Date;
    private readonly _createdAt: Date;
    private readonly _accountBalance: AccountBalance | undefined;

    constructor(account: SerializedChartOfAccounts) {
        this._name = account.name;
        this._description = account.description;
        this._children = account.children.map(
            (childAccount: SerializedChartOfAccounts) => {
                return new ChartOfAccounts(childAccount);
            }
        );
        this._currency = new Currency(account.currency);
        this._organisationId = account.organisation_id;
        this._id = account.id;
        this._createdAt = new Date(account.created_at);
        this._updatedAt = new Date(account.updated_at);

        if (account.account_balance) {
            this._accountBalance = new AccountBalance(account.account_balance);
        }
    }

    get id(): string {
        return this._id;
    }

    get accountBalance(): AccountBalance | undefined {
        return this._accountBalance;
    }

    get name(): string {
        return this._name;
    }

    get organisationId(): string {
        return this._organisationId;
    }

    get children(): Array<ChartOfAccounts> {
        return this._children;
    }

    get description(): string | null {
        return this._description;
    }

    get currency(): Currency {
        return this._currency;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
}
