import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import AccountBalance from '../models/acountBalance';
import currencyFilter from '../filters/currency';
import { Chip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: '8px;',
    },
}));

export interface AccountBalanceChipProps {
    balance: AccountBalance;
    size: 'small' | 'medium';
}

export default function AccountBalanceChip(props: AccountBalanceChipProps) {
    const classes = useStyles();

    const fetchBalance = (balance: AccountBalance): string => {
        return currencyFilter(balance.balance, balance.currency);
    };

    return (
        <Tooltip arrow title="Balance" className={classes.root}>
            <Chip
                label={fetchBalance(props.balance)}
                size={props.size}
                color={props.balance.balance >= 0 ? 'primary' : 'secondary'}
            />
        </Tooltip>
    );
}
