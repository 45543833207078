import Currency, { SerializedCurrency } from './currency';

export interface SerializedCurrencyPair {
    id: string;
    to_currency: SerializedCurrency;
    from_currency: SerializedCurrency;
    valid_from: string;
    valid_until : string  | null;
    rate: number;
    organisation_id: string;
}

export default class CurrencyPair {
    private readonly _id: string;
    private readonly _toCurrency: Currency;
    private readonly _fromCurrency: Currency;
    private readonly _rate: number;
    private readonly _organisationId: string;
    private readonly _validFrom: Date;
    private readonly _validUntil: Date | null;

    constructor(value: SerializedCurrencyPair) {
        this._id = value.id;
        this._toCurrency = new Currency(value.to_currency);
        this._fromCurrency = new Currency(value.from_currency);
        this._rate = value.rate;
        this._organisationId = value.organisation_id;
        this._validFrom = new Date(value.valid_from);
        this._validUntil = value.valid_until ? new Date(value.valid_until) : null;
    }

    get id(): string {
        return this._id;
    }

    get toCurrency(): Currency {
        return this._toCurrency;
    }
    
    get validFrom(): Date {
        return this._validFrom;
    }
    
    get validUntil(): Date | null {
        return this._validUntil;
    }

    get fromCurrency(): Currency {
        return this._fromCurrency;
    }

    get rate(): number {
        return this._rate;
    }

    get organisationId(): string {
        return this._organisationId;
    }
}
