import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function MdiIcon(props: { icon: string }) {
    return (
        <SvgIcon>
            <path d={props.icon} />
        </SvgIcon>
    );
}
