import React from 'react';
import Dashboard from 'pages/dashboard/DashboardIndex';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthLogin from 'pages/auth/AuthLogin';
import Loading from 'pages/utils/UtilLoading';
import NotFound from 'pages/utils/UtilNotFound';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import requireLogin from 'middlewares/requireLogin';
import { setUser } from 'store';
import firebase from 'plugins/firebase';
import { useDispatch } from 'react-redux';
import User from 'models/user';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import OrganisationCreate from 'pages/organisation/OrganisationCreate';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CONSTANTS from 'consts';
import AccountsIndex from './pages/accounts/AccountsIndex';
import TransactionsIndex from './pages/transactions/TransactionsIndex';
import CurrencyPairIndex from './pages/currency-pair/CurrencyPairIndex';

export default function App() {
    const dispatch = useDispatch();

    firebase.auth().onAuthStateChanged((user) => {
        dispatch(setUser(User.serializedUserFromFirebaseUser(user)));
    });
    firebase.auth().onIdTokenChanged((user) => {
        dispatch(setUser(User.serializedUserFromFirebaseUser(user)));
    });

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    primary: {
                        main: '#64b5f6',
                    },
                    type: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode]
    );

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <GuardProvider
                        guards={[requireLogin]}
                        loading={Loading}
                        error={NotFound}
                    >
                        <Switch>
                            <Route
                                path="/auth/login"
                                exact
                                component={AuthLogin}
                            />
                            <GuardedRoute
                                path="/"
                                exact
                                component={Dashboard}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute
                                path={
                                    CONSTANTS.ROUTES.PATHS.ORGANISATION.CREATE
                                }
                                exact
                                component={OrganisationCreate}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute
                                path={CONSTANTS.ROUTES.PATHS.ACCOUNTS.INDEX}
                                exact
                                component={AccountsIndex}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute
                                path={
                                    CONSTANTS.ROUTES.PATHS.CURRENCY_PAIR.INDEX
                                }
                                exact
                                component={CurrencyPairIndex}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute
                                path={CONSTANTS.ROUTES.PATHS.TRANSACTIONS.INDEX}
                                exact
                                component={TransactionsIndex}
                                meta={{ auth: true }}
                            />
                            <GuardedRoute path="*" component={NotFound} />
                        </Switch>
                    </GuardProvider>
                </Router>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}
