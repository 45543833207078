import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import AccountBalance from '../models/acountBalance';
import currencyFilter from '../filters/currency';

export interface AccountBalanceWithTooltipProps {
    balance: AccountBalance;
}

export default function AccountBalanceWithTooltip(
    props: AccountBalanceWithTooltipProps
) {
    const fetchBalance = (balance: AccountBalance): string => {
        return currencyFilter(balance.balance, balance.currency);
    };

    return (
        <Box display="flex" p={1}>
            <Box>
                <Tooltip arrow title="Balance">
                    <Typography variant="subtitle2">
                        {fetchBalance(props.balance)}
                    </Typography>
                </Tooltip>
            </Box>
        </Box>
    );
}
