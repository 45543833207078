import React from 'react';
import { DateNumberPair } from '../models/keyValuePair';
import Typography from '@material-ui/core/Typography';
import Currency from '../models/currency';
import currencyFilter from '../filters/currency';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Box from '@material-ui/core/Box';
import { green, red } from '@material-ui/core/colors';
import { ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
    noPadding: {
        '&:last-child': {
            paddingBottom: 16,
        },
    },

    mbn2: {
        marginBottom: theme.spacing(-0.5),
    },
    mlAuto: {
        marginLeft: 'auto',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(2),
    },
}));

export default function ChartTooltipContent(props: any) {
    props = props as {
        data: Array<DateNumberPair>;
        currency: Currency;
        timezone: string;
        payload: Array<{ payload: DateNumberPair }>;
        active: boolean;
    };
    const classes = useStyles();

    const delta = (): number | null => {
        let index = props.data.findIndex(
            (item: DateNumberPair) => item.key === props.payload[0].payload.key
        );
        if (index < 1) {
            return null;
        }

        let prev = props.data[index - 1];
        if (prev.value === 0 || props.data[index].value === 0) {
            return null;
        }

        return Math.round(
            ((props.data[index].value - prev.value) * 100) /
                Math.abs(prev.value)
        );
    };

    if (props.active && props.payload && props.payload.length) {
        return (
            <Card>
                <CardContent className={classes.noPadding}>
                    <Typography variant="h6">
                        {currencyFilter(
                            props.payload[0].payload.value,
                            props.currency
                        )}
                    </Typography>
                    <Box display="flex">
                        <Typography color="textSecondary">
                            {props.payload[0].payload.key.toLocaleString(
                                undefined,
                                {
                                    timeZone: props.timezone,
                                    year: 'numeric',
                                    month: 'short',
                                }
                            )}
                        </Typography>
                        {delta() !== null && (
                            <Typography
                                className={classes.mlAuto}
                                style={{
                                    color:
                                        (delta() as number) >= 0
                                            ? green[500]
                                            : red[500],
                                }}
                            >
                                {(delta() as number) >= 0 && (
                                    <ArrowUpwardIcon
                                        fontSize="small"
                                        className={classes.mbn2}
                                    />
                                )}
                                {(delta() as number) < 0 && (
                                    <ArrowDownward
                                        fontSize="small"
                                        className={classes.mbn2}
                                    />
                                )}
                                {Math.abs(delta() as number)}%
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
        );
    }

    return null;
}
