import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mdiScaleBalance } from '@mdi/js';

export default function BalanceIcon() {
    return (
        <SvgIcon>
            <path d={mdiScaleBalance} />
        </SvgIcon>
    );
}
