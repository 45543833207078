import React, { ChangeEvent, useState } from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from './LoadingButton';
import { mdiFilterOutline, mdiFilter } from '@mdi/js';
import MdiIcon from './MdiIcon';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 230,
    },

    leftBox: {
        marginLeft: 'auto',
    },
}));

export interface SortParams {
    query: string;
    grouped: boolean;
    sortBy: string;
    sortOrder: number;
}

export interface TransactionFilterProps {
    sortParams: SortParams;
    disabled: boolean;
    onUpdate: (params: SortParams) => void;
}

export default function TransactionFilter(props: TransactionFilterProps) {
    const classes = useStyles();
    const [sortParams, setSortParams] = useState<SortParams>({
        ...props.sortParams,
    });

    const filterIcon = (
        <MdiIcon icon={sortParams.query ? mdiFilter : mdiFilterOutline} />
    );

    const sortValue = (key: string, order: number): string =>
        key + '_' + order.toString();

    const sortKeys: Array<string> = ['timestamp'];

    const capitalize = (str: string) =>
        str[0].toUpperCase() + str.substr(1).toLowerCase();

    return (
        <Box display="flex">
            <Box marginTop={4} paddingLeft={1}>
                <FormControlLabel
                    control={<Switch checked={props.sortParams.grouped} size="small" />}
                    label="Grouped"
                />
            </Box>
            <Box
                display="flex"
                className={classes.leftBox}
                flexDirection="row-reverse"
                p={1}
                m={1}
            >
                <Box marginLeft={1}>
                    <LoadingButton
                        size="medium"
                        Icon={filterIcon}
                        isInputButton={true}
                        color="primary"
                        isLoading={props.disabled}
                        onClick={() => props.onUpdate(sortParams)}
                        label="Filter"
                    />
                </Box>
                <Box marginLeft={1}>
                    <FormControl className={classes.formControl} size="small">
                        <Select
                            variant="outlined"
                            placeholder="Sort"
                            disabled={props.disabled}
                            value={sortValue(
                                sortParams.sortBy,
                                sortParams.sortOrder
                            )}
                            onChange={(
                                event: React.ChangeEvent<{ value: unknown }>
                            ) => {
                                const value = event.target.value as string;
                                setSortParams({
                                    ...sortParams,
                                    sortOrder: parseInt(value.split('_')[1]),
                                    sortBy: value.split('_')[0],
                                });
                            }}
                        >
                            {sortKeys.map((key: string) => {
                                return [
                                    <MenuItem value={sortValue(key, 1)}>
                                        {capitalize(key)} Ascending
                                    </MenuItem>,
                                    <MenuItem value={sortValue(key, -1)}>
                                        {capitalize(key)} Descending
                                    </MenuItem>,
                                ];
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <TextField
                        variant="outlined"
                        placeholder="Query"
                        size="small"
                        disabled={props.disabled}
                        value={sortParams.query}
                        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                            setSortParams({
                                ...sortParams,
                                query: event.target.value,
                            });
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
