import CurrencyBalance, { SerializedCurrencyBalance } from './currencyBalance';

export interface SerializedAccountBalance extends SerializedCurrencyBalance {
    currency_balances: Array<SerializedCurrencyBalance>;
}

export default class AccountBalance extends CurrencyBalance {
    private readonly _currencyBalances: Array<CurrencyBalance>;
    constructor(accountBalance: SerializedAccountBalance) {
        super(accountBalance);
        this._currencyBalances = accountBalance.currency_balances.map(
            (balance: SerializedCurrencyBalance) => {
                return new CurrencyBalance(balance);
            }
        );
    }

    get currencyBalances(): Array<CurrencyBalance> {
        return this._currencyBalances;
    }
}
