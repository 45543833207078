import ValidationErrors from './ValidationErrors';
import { StatusCodes } from 'http-status-codes';

class ErrorHandler {
    errorMessage(error: any) {
        if (error && error?.response?.data?.title) {
            return error.response.data.title;
        }
        if (error && error?.response?.data?.message) {
            return error.response.data.message;
        }
        if (error instanceof Error) {
            return error.message;
        }
        return 'An error occured while carrying out the request. Try again later';
    }

    getFormErrors(error: any): ValidationErrors {
        const errors = new ValidationErrors();
        if (
            typeof error.response?.data?.errors !== 'object' ||
            error.response?.data?.errors === null ||
            error.response?.status !== StatusCodes.BAD_REQUEST
        ) {
            return errors;
        }

        Object.keys(error.response.data.errors).forEach((key: string) => {
            errors.addMany(
                key,
                error.response?.data.errors[key].map((error: string) => {
                    let replaceKey = key;

                    if (key.indexOf('.') !== -1) {
                        replaceKey = key.substr(key.indexOf('.'));
                    }

                    replaceKey = ErrorHandler.toTitleCase(
                        replaceKey.split('_').join(' ')
                    );

                    return error.replace(`'${key}'`, `${replaceKey}`);
                })
            );
        });

        return errors;
    }

    private static toTitleCase(value: string): string {
        return value
            .split(' ')
            .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase())
            .join(' ');
    }
}

export default new ErrorHandler();
