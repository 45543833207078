import Currency, { SerializedCurrency } from './currency';

export interface SerializedCurrencyBalance {
    currency: SerializedCurrency;
    debits: number;
    credits: number;
    balance: number;
}

export default class CurrencyBalance {
    private readonly _currency: Currency;
    private readonly _debits: number;
    private readonly _credits: number;
    private readonly _balance: number;

    constructor(currencyBalance: SerializedCurrencyBalance) {
        this._currency = new Currency(currencyBalance.currency);
        this._debits = currencyBalance.debits;
        this._credits = currencyBalance.credits;
        this._balance = currencyBalance.balance;
    }

    get currency(): Currency {
        return this._currency;
    }

    get debits(): number {
        return this._debits;
    }

    get credits(): number {
        return this._credits;
    }

    get balance(): number {
        return this._balance;
    }
}
