import { currentUserWithDelay } from 'plugins/firebase';
import axios, { AxiosResponse, AxiosInstance } from 'axios';

export interface IndexOptions {
    take: number;
    skip: number;
    sortBy: string;
    sortOrder: number;
    query: string;
}

export default abstract class BaseApi {
    private httpClient: AxiosInstance;
    constructor() {
        this.httpClient = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        });
    }

    protected authPost(url: string, data: any): Promise<AxiosResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await currentUserWithDelay();
                const token = await user.getIdToken();
                resolve(
                    this.httpClient.post(url, data, {
                        headers: { Authorization: 'Bearer ' + token },
                    })
                );
            } catch (error) {
                if (error instanceof Error) {
                    reject(error);
                    return;
                }
                reject(new Error(JSON.stringify(error)));
            }
        });
    }

    protected authPatch(url: string, data: any): Promise<AxiosResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await currentUserWithDelay();
                const token = await user.getIdToken();
                resolve(
                    this.httpClient.patch(url, data, {
                        headers: { Authorization: 'Bearer ' + token },
                    })
                );
            } catch (error) {
                if (error instanceof Error) {
                    reject(error);
                    return;
                }
                reject(new Error(JSON.stringify(error)));
            }
        });
    }

    protected authDelete(url: string): Promise<AxiosResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await currentUserWithDelay();
                const token = await user.getIdToken();
                resolve(
                    this.httpClient.delete(url, {
                        headers: { Authorization: 'Bearer ' + token },
                    })
                );
            } catch (error) {
                if (error instanceof Error) {
                    reject(error);
                    return;
                }
                reject(new Error(JSON.stringify(error)));
            }
        });
    }

    protected authGet(
        url: string,
        params: object = {}
    ): Promise<AxiosResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await currentUserWithDelay();
                const token = await user.getIdToken();
                resolve(
                    this.httpClient.get(url, {
                        headers: { Authorization: 'Bearer ' + token },
                        params,
                    })
                );
            } catch (error) {
                if (error instanceof Error) {
                    reject(error);
                    return;
                }
                reject(new Error(JSON.stringify(error)));
            }
        });
    }
}
