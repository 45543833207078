import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: 'AIzaSyC_rW2wSdt706Z7TiCxgP6rVVNoucHN9i4',
    authDomain: 'alizep-22ae9.firebaseapp.com',
    projectId: 'alizep-22ae9',
    storageBucket: 'alizep-22ae9.appspot.com',
    messagingSenderId: '141786064169',
    appId: '1:141786064169:web:f14e5d17150742e1078338',
    measurementId: 'G-RZFJSFEFY9',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const currentUserWithDelay = (): Promise<firebase.User> => {
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            if (firebase.auth().currentUser) {
                clearInterval(interval);
                resolve(firebase.auth().currentUser as firebase.User);
            }
        }, 500);
        setTimeout(() => {
            reject(new Error('Timeout while fetching user'));
        }, 15000);
    });
};

export default firebase;
