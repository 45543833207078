import {AxiosResponse} from 'axios';
import BaseApi from './BaseApi';

export interface NewCurrencyPairRequest {
    to_currency: string;
    from_currency: string;
    valid_from: Date | null;
    valid_until: Date | null;
    rate: number;
    captcha: string;
}

class CurrencyPairApi extends BaseApi {
    create(
        organisationId: string,
        request: NewCurrencyPairRequest
    ): Promise<AxiosResponse> {
        return this.authPost(`/${organisationId}/currency-pairs`, request);
    }

    index(organisationId: string): Promise<AxiosResponse> {
        return this.authGet(`/${organisationId}/currency-pairs`);
    }

    delete(organisationId: string, pairId: string): Promise<AxiosResponse> {
        return this.authDelete(`/${organisationId}/currency-pairs/${pairId}`);
    }
}

export default new CurrencyPairApi();
