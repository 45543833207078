import Currency, { SerializedCurrency } from 'models/currency';
import { InputTransaction } from '../components/TransactionInput';
import currency from '../filters/currency';

export interface SerializedTransaction {
    id: string;
    description: string;
    reference: string;
    notes: string;
    timestamp: string;
    amount: number;
    organisation_id: string;
    account_id: string;
    updated_at: string;
    created_at: string;
    direction: string;
    currency: SerializedCurrency;
    source: string;
}

export default class Transaction {
    private readonly _id: string;
    private readonly _organisationId: string;
    private readonly _accountId: string;
    private readonly _reference: string;
    private readonly _timestamp: Date;
    private readonly _amount: number;
    private readonly _notes: string;
    private readonly _description: string;
    private readonly _currency: Currency;
    private readonly _updatedAt: Date;
    private readonly _createdAt: Date;
    private readonly _direction: string;
    private readonly _source: string;

    constructor(transaction: SerializedTransaction) {
        this._description = transaction.description;
        this._currency = new Currency(transaction.currency);
        this._organisationId = transaction.organisation_id;
        this._id = transaction.id;
        this._amount = transaction.amount;
        this._reference = transaction.reference;
        this._notes = transaction.notes;
        this._timestamp = new Date(transaction.timestamp);
        this._createdAt = new Date(transaction.created_at);
        this._updatedAt = new Date(transaction.updated_at);
        this._accountId = transaction.account_id;
        this._direction = transaction.direction;
        this._source = transaction.source;
    }

    get direction(): string {
        return this._direction;
    }

    get isDeposit(): boolean {
        return this._direction.toLowerCase() === 'deposit';
    }

    get accountId(): string {
        return this._accountId;
    }

    get id(): string {
        return this._id;
    }

    get organisationId(): string {
        return this._organisationId;
    }

    get reference(): string {
        return this._reference;
    }

    get timestamp(): Date {
        return this._timestamp;
    }

    get amount(): number {
        return this._amount;
    }

    get notes(): string {
        return this._notes;
    }

    get description(): string {
        return this._description;
    }

    get currency(): Currency {
        return this._currency;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get displayAmountValue(): string {
        return currency(this.amount, this.currency);
    }

    toInputTransaction(): InputTransaction {
        return {
            account_id: this.accountId,
            amount: this.amount,
            currency: this.currency.symbol,
            description: this.description,
            direction: this.direction,
            id: this.id,
            notes: this.notes,
            reference: this.reference,
            source: 'website',
            timestamp: this.timestamp
                .toISOString()
                .substr(0, this.timestamp.toISOString().lastIndexOf('.')),
        };
    }

    serialize(): SerializedTransaction {
        return {
            id: this.id,
            description: this.description,
            reference: this.reference,
            notes: this.notes,
            timestamp: this.timestamp.toISOString(),
            amount: this.amount,
            direction: this.direction,
            organisation_id: this.organisationId,
            account_id: this.accountId,
            updated_at: this.updatedAt.toISOString(),
            created_at: this.createdAt.toISOString(),
            currency: this.currency.serialize(),
            source: this._source,
        };
    }
}
