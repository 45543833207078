import { GlobalState } from './index';

export function createOrganisationRoute(state: GlobalState, route: string) {
    if (!state.organisationId) {
        throw new Error(
            `Cannot create organisation route: orgID = ${state.organisationId} route = ${route}`
        );
    }
    return route.replace(':organisationId', state.organisationId);
}
