import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import CardTitle from './CardTitle';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { DateNumberPair } from '../models/keyValuePair';
import ChartTooltipContent from './ChartTooltipContent';
import Currency from '../models/currency';

const useStyles = makeStyles((theme: Theme) => ({
    depositContext: {
        flex: 1,
    },
    fixedHeight: {
        height: 240,
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export interface DashboardChartProps {
    label: string;
    data: Array<DateNumberPair>;
    timezone: string;
    currency: Currency;
    yAxisLabel: string;
    lineColor?: string;
}

export default function DashboardChart(props: DashboardChartProps) {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Grid item xs={12} md={8} lg={9}>
            <Paper className={classes.fixedHeight}>
                <CardTitle>{props.label}</CardTitle>
                <ResponsiveContainer>
                    <LineChart
                        data={props.data}
                        margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                        }}
                    >
                        <XAxis
                            dataKey="key"
                            stroke={theme.palette.text.secondary}
                            tickFormatter={(tick: Date) =>
                                tick.toLocaleString(undefined, {
                                    timeZone: props.timezone,
                                    year: 'numeric',
                                    month: 'short',
                                })
                            }
                        />
                        <Tooltip
                            content={
                                <ChartTooltipContent
                                    timezone={props.timezone}
                                    currency={props.currency}
                                    data={props.data}
                                />
                            }
                        />
                        <YAxis
                            stroke={theme.palette.text.secondary}
                            tickFormatter={(value: number) => {
                                if (value > 100000 || value < 100000) {
                                    return Math.round(value / 100000) + 'k';
                                }

                                return value.toString();
                            }}
                        >
                            <Label
                                angle={270}
                                position="left"
                                style={{
                                    textAnchor: 'middle',
                                    fill: theme.palette.text.primary,
                                }}
                            >
                                {props.yAxisLabel}
                            </Label>
                        </YAxis>
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke={
                                props.lineColor ?? theme.palette.primary.main
                            }
                            dot={true}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
        </Grid>
    );
}
