import React, { ChangeEvent } from 'react';
import { MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

export interface CurrencySelectorProps {
    disabled: boolean;
    error: boolean;
    className?: string;
    helperText: string | undefined;
    value: string | null;
    label: string | undefined;
    onChange: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
}

export interface SelectItem {
    text: string;
    value: string;
}

export default function CurrencySelector(props: CurrencySelectorProps) {
    const options: Array<SelectItem> = [
        {
            text: 'Central African Francs (FCFA)',
            value: 'FCFA',
        },
        {
            text: 'Nigerian Naira (NGN)',
            value: 'NGN',
        },
        {
            text: 'United States Dollar (USD)',
            value: 'USD',
        },
        {
            text: 'Euro (EUR)',
            value: 'EUR',
        },
    ];

    return (
        <TextField
            required
            fullWidth
            size="small"
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            select
            className={props.className}
            name="currency"
            margin="normal"
            key="currency"
            label={props.label ? props.label : 'Currency'}
            placeholder="Currency"
            autoComplete="currency"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
        >
            {options.map((option: SelectItem) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
            ))}
        </TextField>
    );
}
