import Transaction, { SerializedTransaction } from './transaction';
import { InputGroupedTransaction } from '../components/TransactionInput';

export interface SerializedGroupedTransaction extends SerializedTransaction {
    children: Array<SerializedTransaction>;
}

export default class GroupedTransaction extends Transaction {
    private readonly _children: Array<Transaction>;

    constructor(account: SerializedGroupedTransaction) {
        super(account);
        this._children = account.children.map(
            (childAccount: SerializedTransaction) => {
                return new Transaction(childAccount);
            }
        );
    }

    get children(): Array<Transaction> {
        return this._children;
    }

    toInputGroupedTransaction(): InputGroupedTransaction {
        return {
            ...super.toInputTransaction(),
            children: this.children.map((child: Transaction) =>
                child.toInputTransaction()
            ),
        };
    }

    serialize(): SerializedGroupedTransaction {
        return {
            ...super.serialize(),
            children: this.children.map((child: Transaction) =>
                child.serialize()
            ),
        };
    }
}
