import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: process.env.APP_ENV,
    release: 'alizep-frontend@' + process.env.REACT_APP_COMMIT_HASH,
});

export const captureSentryError = (error: Error | string) => {
    Sentry.captureException(error);
};
