class TimeSeriesGenerator {
    generateMonthly(
        count: number,
        timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
        end: Date = new Date()
    ): Array<Date> {
        const result: Array<Date> = [];
        for (let i = count; i > 0; i--) {
            result.push(
                this.dateWithTimeZone(
                    timezone,
                    end.getFullYear(),
                    end.getMonth() + 2 - i,
                    0,
                    23,
                    59,
                    59
                )
            );
        }
        return result;
    }

    dateWithTimeZone = (
        timeZone: string,
        year: number,
        month: number,
        day: number,
        hour: number,
        minute: number,
        second: number
    ) => {
        let date = new Date(Date.UTC(year, month, day, hour, minute, second));

        let utcDate = new Date(
            date.toLocaleString('en-US', { timeZone: 'UTC' })
        );
        let tzDate = new Date(
            date.toLocaleString('en-US', { timeZone: timeZone })
        );
        let offset = utcDate.getTime() - tzDate.getTime();

        date.setTime(date.getTime() + offset);

        return date;
    };
}

export default new TimeSeriesGenerator();
