export default class MessageBag<K extends number | string, T> {
    private items = new Map<K, Array<T>>();

    add(key: K, value: T): this {
        let messages: Array<T> | undefined = this.items.get(key);
        if (messages === undefined) {
            messages = [];
        }

        if (!messages.includes(value)) {
            messages.push(value);
        }

        this.items.set(key, messages);

        return this;
    }

    clear(key: K): this {
        this.items.delete(key);
        return this;
    }

    addMany(key: K, values: Array<T>): this {
        values.forEach((value: T) => {
            this.add(key, value);
        });
        return this;
    }

    has(key: K): boolean {
        return this.items.has(key);
    }

    first(key: K): T | undefined {
        if (this.has(key)) {
            return this.get(key)[0] ?? undefined;
        }
        return undefined;
    }

    get(key: K): Array<T> {
        const result = this.items.get(key);
        if (result === undefined) {
            return [];
        }
        return result;
    }

    keys(): Array<K> {
        return Array.from(this.items.keys());
    }

    size(): number {
        return this.items.size;
    }

    isEmpty(): boolean {
        return this.size() === 0;
    }
}
