import Currency from '../models/currency';

export default function currency(
    amount: string | number | null,
    currency: Currency
): string {
    const amountAsNumber = parseInt(amount as string);
    if (amount === null || amount === '' || isNaN(amountAsNumber)) {
        return '-';
    }

    const symbols = new Map<string, string>([
        ['EUR', 'EUR'],
        ['USD', 'USD'],
        ['NGN', 'NGN'],
        ['FCFA', 'XAF'],
        ['XAF', 'XAF'],
    ]);

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: symbols.get(currency.symbol),
    }).format(amountAsNumber / 100.0);
}
