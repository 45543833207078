import firebase from 'plugins/firebase';
export const userIsLoggedIn = () => {
    return !!JSON.parse(localStorage.getItem('user') || '');
};

export interface SerializedUser {
    id: string;
    email: string;
}

export default class User {
    private readonly _id: string;
    private readonly _email: string;

    constructor(user: { id: string; email: string }) {
        this._id = user.id;
        this._email = user.email;
    }

    static fromFirebaseUser(user: firebase.UserInfo | null): User | null {
        if (!user) {
            return null;
        }
        return new User({
            id: user.uid,
            email: user.email as string,
        });
    }

    static serializedUserFromFirebaseUser(
        user: firebase.UserInfo | null
    ): SerializedUser | null {
        if (!user) {
            return null;
        }
        return new User({
            id: user.uid,
            email: user.email as string,
        }).serialize();
    }

    get email(): string {
        return this._email;
    }

    get id(): string {
        return this._id;
    }

    serialize(): SerializedUser {
        return {
            id: this.id,
            email: this.email,
        };
    }
}
