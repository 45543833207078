import React, { MouseEvent, ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
    loadingSpinner: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        marginTop: 5,
        marginLeft: -12,
    },
    inputButtonLabel: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    none: {},
}));

export interface LoadingButtonProps {
    size: 'small' | 'large' | 'medium';
    color: 'primary' | 'secondary';
    Icon?: ReactElement;
    isLoading: boolean;
    isInputButton?: boolean;
    label: string;
    onClick?: (event: MouseEvent) => void;
}

export default function LoadingButton(props: LoadingButtonProps) {
    const classes = useStyles();
    return (
        <Button
            type="submit"
            size={props.size}
            variant="contained"
            color={props.color}
            disabled={props.isLoading}
            startIcon={props.Icon}
            onClick={props.onClick}
        >
            {props.isLoading && (
                <CircularProgress
                    size={24}
                    className={classes.loadingSpinner}
                />
            )}
            <div
                className={
                    props.isInputButton
                        ? classes.inputButtonLabel
                        : classes.none
                }
            >
                {props.label}
            </div>
        </Button>
    );
}
