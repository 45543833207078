const routes = {
    PATHS: {
        DASHBOARD: '/',
        ORGANISATION: {
            CREATE: '/organisations/create',
        },
        ACCOUNTS: {
            INDEX: '/:organisationId/accounts',
        },
        TRANSACTIONS: {
            INDEX: '/:organisationId/transactions',
        },
        AUTH: {
            LOGIN: '/auth/login',
        },
        CURRENCY_PAIR: {
            INDEX: '/:organisationId/currency-pairs',
        },
    },
};

export default routes;
