import React, { ChangeEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DirectionSelector from './DirectionSelector';
import AccountSelector from './AccountSelector';
import CurrencySelector from './CurrencySelector';
import ValidationErrors from '../services/errors/ValidationErrors';
import { NewTransaction } from '../services/apis/TransactionApi';
import ChartOfAccounts from '../models/chartOfAccounts';
import { v4 as uuidv4 } from 'uuid';

export interface TransactionInputProps {
    isLoading: boolean;
    formErrors: ValidationErrors;
    transaction: InputTransaction;
    accounts: Array<ChartOfAccounts>;
    onChange: (newTransaction: InputTransaction) => void;
}

export interface InputTransaction extends NewTransaction {
    id: string;
}

export interface InputGroupedTransaction extends InputTransaction {
    children: Array<InputTransaction>;
}

export const makeInputGroupedTransaction = (): InputGroupedTransaction => {
    return {
        ...makeTransaction(),
        children: [],
    };
};

export const makeTransaction = (): InputTransaction => {
    return {
        id: uuidv4().toString(),
        description: '',
        reference: '',
        notes: '',
        timestamp: new Date()
            .toISOString()
            .substr(0, new Date().toISOString().lastIndexOf('.')),
        direction: 'deposit',
        account_id: '',
        currency: 'FCFA',
        amount: 0,
        source: 'website',
    };
};

export default function TransactionInput(props: TransactionInputProps) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Description')}
                    helperText={props.formErrors.first('Description')}
                    name="description"
                    margin="normal"
                    label="Description"
                    variant="outlined"
                    value={props.transaction.description}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        props.onChange({
                            ...props.transaction,
                            description: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Reference')}
                    helperText={props.formErrors.first('Reference')}
                    name="reference"
                    margin="normal"
                    label="Reference"
                    variant="outlined"
                    value={props.transaction.reference}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        props.onChange({
                            ...props.transaction,
                            reference: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    size="small"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Notes')}
                    helperText={props.formErrors.first('Notes')}
                    name="notes"
                    margin="normal"
                    label="Notes"
                    variant="outlined"
                    value={
                        props.transaction.notes === null
                            ? undefined
                            : props.transaction.notes
                    }
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        props.onChange({
                            ...props.transaction,
                            notes: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    size="small"
                    required
                    disabled={props.isLoading}
                    error={props.formErrors.has('Timestamp')}
                    helperText={props.formErrors.first('Timestamp')}
                    label="Timestamp"
                    variant="outlined"
                    margin="normal"
                    name="timestamp"
                    defaultValue={props.transaction.timestamp}
                    type="datetime-local"
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        props.onChange({
                            ...props.transaction,
                            timestamp: event.target.value,
                        });
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <DirectionSelector
                    label="Direction"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Direction')}
                    helperText={props.formErrors.first('Direction')}
                    value={props.transaction.direction}
                    onChange={(
                        event: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                        >
                    ) => {
                        props.onChange({
                            ...props.transaction,
                            direction: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <AccountSelector
                    disabled={props.isLoading}
                    error={props.formErrors.has('AccountId')}
                    accounts={props.accounts}
                    helperText={props.formErrors.first('AccountId')}
                    value={props.transaction.account_id}
                    label="Account"
                    onChange={(
                        event: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                        >
                    ) => {
                        console.log(event.target.value);
                        props.onChange({
                            ...props.transaction,
                            account_id: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <CurrencySelector
                    label="Currency"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Currency')}
                    helperText={props.formErrors.first('Currency')}
                    value={props.transaction.currency}
                    onChange={(
                        event: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                        >
                    ) => {
                        props.onChange({
                            ...props.transaction,
                            currency: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    required
                    size="small"
                    disabled={props.isLoading}
                    error={props.formErrors.has('Amount')}
                    helperText={props.formErrors.first('Amount')}
                    label="Amount"
                    variant="outlined"
                    margin="normal"
                    name="amount"
                    value={
                        props.transaction.amount === 0
                            ? ''
                            : props.transaction.amount
                    }
                    type="numeric"
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                        const intValue = parseInt(event.target.value);
                        props.onChange({
                            ...props.transaction,
                            amount: isNaN(intValue) ? 0 : intValue,
                        });
                    }}
                />
            </Grid>
        </Grid>
    );
}
