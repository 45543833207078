import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    spinnerContainer: {
        textAlign: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export default function LoadingSpinner() {
    const classes = useStyles();
    return (
        <div className={classes.spinnerContainer}>
            <CircularProgress size={90} />
        </div>
    );
}
