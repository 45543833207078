import { userIsLoggedIn } from 'models/user';
import {
    GuardFunctionRouteProps,
    GuardToRoute,
    Next,
} from 'react-router-guards/dist/types';

export default function requireLogin(
    to: GuardToRoute,
    from: GuardFunctionRouteProps | null,
    next: Next
) {
    if (to.meta.auth) {
        if (userIsLoggedIn()) {
            next();
        }
        next.redirect('/auth/login');
    } else {
        next();
    }
}
