import React, {
    ChangeEvent,
    FormEvent,
    MouseEvent,
    useEffect,
    useState,
} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import AppLayout from '../../components/AppLayout';
import Grid from '@material-ui/core/Grid';
import {
    Card,
    CardActions,
    CardContent,
    TableCell,
    TablePagination,
    Tooltip,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidationErrors from '../../services/errors/ValidationErrors';
import AccountApi from '../../services/apis/AccountApi';
import { AxiosResponse } from 'axios';
import ChartOfAccounts, {
    SerializedChartOfAccounts,
} from '../../models/chartOfAccounts';
import { captureSentryError } from '../../plugins/sentry';
import ErrorHandler from '../../services/errors';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { useSnackbar } from 'notistack';
import {
    InputGroupedTransaction,
    InputTransaction,
    makeInputGroupedTransaction,
} from '../../components/TransactionInput';
import TransactionApi, {
    CreateTransactionRequest,
    NewGroupedTransaction,
    NewTransaction,
} from '../../services/apis/TransactionApi';
import GroupedTransaction, {
    SerializedGroupedTransaction,
} from '../../models/groupedTransaction';
import LoadingSpinner from '../../components/LoadingSpinner';
import TransactionForm from '../../components/TransactionForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import _ from 'underscore';
import Checkbox from '@material-ui/core/Checkbox';
import {
    MakeTablePaginationOptions,
    TablePaginationOptions,
} from '../../types/material-ui';
import Transformers from '../../transformers';
import TransactionFilter, {
    SortParams,
} from '../../components/TransactionFilter';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Transaction from '../../models/transaction';
import Box from '@material-ui/core/Box';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formCard: {
            marginBottom: theme.spacing(4),
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20,
        },
        details: {
            alignItems: 'center',
        },
        column: {
            flexBasis: '33.33%',
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        loadingSpinner: {
            color: theme.palette.secondary.main,
            position: 'absolute',
            marginTop: 5,
            marginLeft: -12,
        },
        noTransactions: {
            textAlign: 'center',
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        table: {
            minWidth: '100%',
        },
        tableContainer: {
            padding: theme.spacing(5),
        },
    })
);

export default function TransactionsIndex() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [expanded, setExpanded] = useState<string | false>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState<boolean>(
        true
    );
    const [groupedTransactions, setGroupedTransactions] = useState<
        Array<GroupedTransaction>
    >([]);
    const [formErrors, setFormErrors] = useState<ValidationErrors>(
        new ValidationErrors()
    );
    const [accounts, setAccounts] = React.useState(
        [] as Array<ChartOfAccounts>
    );

    const [
        paginationOptions,
        setPaginationOptions,
    ] = useState<TablePaginationOptions>(MakeTablePaginationOptions);

    const [transaction, setTransaction] = useState<InputGroupedTransaction>(
        makeInputGroupedTransaction()
    );

    const [sortParams, setSortParams] = useState<SortParams>({
        query: '',
        grouped: true,
        sortBy: 'timestamp',
        sortOrder: -1,
    } as SortParams);

    const loadTransactions = (
        organisationId: string,
        options: TablePaginationOptions,
        params: SortParams | undefined = undefined
    ) => {
        setIsLoadingTransactions(true);
        TransactionApi.index(
            organisationId,
            Transformers.NewIndexOptions(options, params ?? sortParams)
        )
            .then((response: AxiosResponse) => {
                setGroupedTransactions(
                    response.data.payload.map(
                        (groupedTransaction: SerializedGroupedTransaction) => {
                            return new GroupedTransaction(groupedTransaction);
                        }
                    )
                );
                setIsLoadingTransactions(false);
            })
            .catch((error: Error) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
            });
    };

    const loadChartOfAccounts = (organisationId: string) => {
        setIsLoading(true);
        AccountApi.index(organisationId)
            .then((response: AxiosResponse) => {
                setAccounts(
                    response.data.payload.map(
                        (account: SerializedChartOfAccounts) => {
                            return new ChartOfAccounts(account);
                        }
                    )
                );
                setIsLoading(false);
                loadTransactions(organisationId, paginationOptions);
            })
            .catch((error: Error) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
            });
    };

    const organisationId = useSelector(
        (state: GlobalState) => state.organisationId
    );

    const toggleTransaction = (panel: string) => (
        event: ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClose = () => {
        setExpanded(false);
    };

    const createTransaction = (
        organisationId: string,
        captcha: string,
        input: InputGroupedTransaction
    ) => {
        setFormErrors(new ValidationErrors());
        const request = {
            ...inputGroupedTransactionToNewGroupedTransaction(input),
            captcha,
        } as CreateTransactionRequest;
        TransactionApi.create(organisationId, request)
            .then((response: AxiosResponse) => {
                enqueueSnackbar(response.data.message, { variant: 'info' });
                setIsLoading(false);
                setTransaction(makeInputGroupedTransaction());
                loadTransactions(organisationId, paginationOptions);
            })
            .catch((error: Error | string) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setFormErrors(
                    replaceChildIndexToId(
                        input,
                        ErrorHandler.getFormErrors(error)
                    )
                );
                setIsLoading(false);
            });
    };

    const replaceChildIndexToId = (
        transaction: InputGroupedTransaction,
        errors: ValidationErrors
    ): ValidationErrors => {
        const result = new ValidationErrors();

        const childIds = transaction.children.map((child: InputTransaction) => {
            return child.id;
        });

        errors.keys().forEach((key: string) => {
            if (key.indexOf('.') === -1) {
                result.addMany(key, errors.get(key));
            } else {
                const value = key.substr(
                    key.indexOf('[') + 1,
                    key.indexOf(']') - key.indexOf('[') - 1
                );
                result.addMany(
                    key.replace(value, childIds[parseInt(value)]),
                    errors.get(key)
                );
            }
        });
        return result;
    };

    const inputGroupedTransactionToNewGroupedTransaction = (
        input: InputGroupedTransaction
    ): NewGroupedTransaction => {
        return _.omit(
            {
                ...input,
                source: 'website',
                children: input.children.map<NewTransaction>(
                    (input: InputTransaction) => _.omit(input, 'id')
                ),
            },
            'id'
        );
    };

    const onTransactionAdd = (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        executeRecaptcha('create_transaction')
            .then((captcha: string) => {
                createTransaction(
                    organisationId as string,
                    captcha,
                    transaction
                );
            })
            .catch((error) => {
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setIsLoading(false);
            });
    };

    const accountNameById = (id: string): string | null => {
        const findByAccountAndId = (
            account: ChartOfAccounts,
            id: string
        ): string | null => {
            if (account.id === id) {
                return account.name;
            }
            for (let index = 0; index < account.children.length; index++) {
                const name = findByAccountAndId(account.children[index], id);
                if (name !== null) {
                    return name;
                }
            }
            return null;
        };

        for (let i = 0; i < accounts.length; i++) {
            let name = findByAccountAndId(accounts[i], id);
            if (name) {
                return name;
            }
        }
        return null;
    };

    const [
        updatedTransaction,
        setUpdatedTransaction,
    ] = useState<InputGroupedTransaction>(makeInputGroupedTransaction());

    const updateTransaction = (
        organisationId: string,
        captcha: string,
        input: InputGroupedTransaction
    ) => {
        setFormErrors(new ValidationErrors());
        const request = {
            ...inputGroupedTransactionToNewGroupedTransaction(input),
            captcha,
        } as CreateTransactionRequest;
        TransactionApi.update(organisationId, input.id, request)
            .then((response: AxiosResponse) => {
                enqueueSnackbar(response.data.message, { variant: 'info' });
                setIsLoading(false);
                setTransaction(makeInputGroupedTransaction());
                loadTransactions(organisationId, paginationOptions);
            })
            .catch((error: Error | string) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setFormErrors(
                    replaceChildIndexToId(
                        input,
                        ErrorHandler.getFormErrors(error)
                    )
                );
                setIsLoading(false);
            });
    };

    const deleteTransaction = (
        organisationId: string,
        transactionId: string,
    ) => {
        TransactionApi.delete(organisationId, transactionId)
            .then((response: AxiosResponse) => {
                enqueueSnackbar(response.data.message, { variant: 'info' });
                setIsLoading(false);
                setTransaction(makeInputGroupedTransaction());
                loadTransactions(organisationId, paginationOptions);
            })
            .catch((error: Error | string) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setIsLoading(false);
            });
    };

    const onTransactionUpdate = (event: MouseEvent) => {
        event.preventDefault();
        setIsLoading(true);
        executeRecaptcha('update_transaction')
            .then((captcha: string) => {
                updateTransaction(
                    organisationId as string,
                    captcha,
                    updatedTransaction
                );
            })
            .catch((error) => {
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setIsLoading(false);
            });
    };
    
    const handleDelete = (transactionId: string) => {
        setIsLoading(true);
        deleteTransaction(organisationId as string, transactionId);
    }

    const onUpdateTransaction = (input: InputGroupedTransaction) => {
        setUpdatedTransaction(input);
    };

    const onTransactionFilterUpdate = (params: SortParams) => {
        let options = MakeTablePaginationOptions();
        setPaginationOptions(options);
        setSortParams(params);
        loadTransactions(organisationId as string, options, params);
    };

    useEffect(() => {
        if (organisationId) {
            loadChartOfAccounts(organisationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLayout title="Transactions">
            <Container maxWidth={false} className={classes.container}>
                <form onSubmit={onTransactionAdd} noValidate>
                    <Card className={classes.formCard}>
                        <CardContent>
                            <Typography
                                component="h2"
                                variant="h5"
                                gutterBottom
                            >
                                Add Transaction
                            </Typography>
                            <TransactionForm
                                isLoading={isLoading}
                                formErrors={formErrors}
                                transaction={transaction}
                                accounts={accounts}
                                onChange={setTransaction}
                            />
                        </CardContent>
                        <CardActions>
                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                startIcon={<CreateIcon />}
                            >
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.loadingSpinner}
                                    />
                                )}
                                Save Transaction
                            </Button>
                        </CardActions>
                    </Card>
                </form>
                <TransactionFilter
                    sortParams={sortParams}
                    onUpdate={onTransactionFilterUpdate}
                    disabled={isLoadingTransactions}
                />
                {isLoadingTransactions && <LoadingSpinner />}
                {!isLoadingTransactions && groupedTransactions.length === 0 && (
                    <Card>
                        <CardContent>
                            <Typography
                                variant="h5"
                                component="h2"
                                className={classes.noTransactions}
                            >
                                You don't have any transactions... yet
                            </Typography>
                        </CardContent>
                    </Card>
                )}
                {!isLoadingTransactions &&
                    groupedTransactions.length > 0 &&
                    groupedTransactions.map(
                        (transaction: GroupedTransaction) => {
                            return (
                                <Accordion
                                    key={transaction.id}
                                    expanded={expanded === transaction.id}
                                    onChange={toggleTransaction(transaction.id)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <Grid item xs={1}>
                                                <Checkbox
                                                    onClick={(event) =>
                                                        event.stopPropagation()
                                                    }
                                                    onFocus={(event) =>
                                                        event.stopPropagation()
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    {transaction.timestamp.toDateString()}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    className={classes.heading}
                                                >
                                                    {transaction.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography
                                                    className={
                                                        classes.secondaryHeading
                                                    }
                                                >
                                                    {transaction.reference}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    {accountNameById(
                                                        transaction.accountId
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography>
                                                    {
                                                        transaction.displayAmountValue
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    {expanded === transaction.id && <Divider />}
                                    <AccordionDetails>
                                        {updatedTransaction.id !==
                                            transaction.id && (
                                            <TableContainer
                                                className={
                                                    classes.tableContainer
                                                }
                                            >
                                                <Table
                                                    size="small"
                                                    className={classes.table}
                                                    aria-label="transaction breakdown"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Date
                                                            </TableCell>
                                                            <TableCell>
                                                                Description
                                                            </TableCell>
                                                            <TableCell>
                                                                Reference
                                                            </TableCell>
                                                            <TableCell>
                                                                Account
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Debit Amount
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Credit Amount
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            hover
                                                            key={transaction.id}
                                                        >
                                                            <TableCell>
                                                                {transaction.timestamp.toDateString()}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    transaction.description
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    transaction.reference
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {accountNameById(
                                                                    transaction.accountId
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {!transaction.notes &&
                                                                    !transaction.isDeposit && (
                                                                        <Typography variant="subtitle2">
                                                                            {
                                                                                transaction.displayAmountValue
                                                                            }
                                                                        </Typography>
                                                                    )}
                                                                {transaction.notes &&
                                                                    !transaction.isDeposit && (
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="row-reverse"
                                                                        >
                                                                            <Tooltip
                                                                                title={
                                                                                    transaction.notes
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <Typography variant="subtitle2">
                                                                                    {
                                                                                        transaction.displayAmountValue
                                                                                    }
                                                                                </Typography>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    )}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {!transaction.notes &&
                                                                    transaction.isDeposit && (
                                                                        <Typography variant="subtitle2">
                                                                            {
                                                                                transaction.displayAmountValue
                                                                            }
                                                                        </Typography>
                                                                    )}
                                                                {transaction.notes &&
                                                                    transaction.isDeposit && (
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="row-reverse"
                                                                        >
                                                                            <Tooltip
                                                                                title={
                                                                                    transaction.notes
                                                                                }
                                                                                arrow
                                                                            >
                                                                                <Typography variant="subtitle2">
                                                                                    {
                                                                                        transaction.displayAmountValue
                                                                                    }
                                                                                </Typography>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    )}
                                                            </TableCell>
                                                        </TableRow>
                                                        {transaction.children.map(
                                                            (
                                                                childTransaction: Transaction
                                                            ) => (
                                                                <TableRow
                                                                    hover
                                                                    key={
                                                                        childTransaction.id
                                                                    }
                                                                >
                                                                    <TableCell>
                                                                        {childTransaction.timestamp.toDateString()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            childTransaction.description
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            childTransaction.reference
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {accountNameById(
                                                                            childTransaction.accountId
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {!childTransaction.notes &&
                                                                            !childTransaction.isDeposit && (
                                                                                <Typography variant="subtitle2">
                                                                                    {
                                                                                        childTransaction.displayAmountValue
                                                                                    }
                                                                                </Typography>
                                                                            )}
                                                                        {childTransaction.notes &&
                                                                            !childTransaction.isDeposit && (
                                                                                <Box
                                                                                    display="flex"
                                                                                    flexDirection="row-reverse"
                                                                                >
                                                                                    <Tooltip
                                                                                        title={
                                                                                            childTransaction.notes
                                                                                        }
                                                                                        arrow
                                                                                    >
                                                                                        <Typography variant="subtitle2">
                                                                                            {
                                                                                                childTransaction.displayAmountValue
                                                                                            }
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            )}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {!childTransaction.notes &&
                                                                            childTransaction.isDeposit && (
                                                                                <Typography variant="subtitle2">
                                                                                    {
                                                                                        childTransaction.displayAmountValue
                                                                                    }
                                                                                </Typography>
                                                                            )}
                                                                        {childTransaction.notes &&
                                                                            childTransaction.isDeposit && (
                                                                                <Box
                                                                                    display="flex"
                                                                                    flexDirection="row-reverse"
                                                                                >
                                                                                    <Tooltip
                                                                                        title={
                                                                                            childTransaction.notes
                                                                                        }
                                                                                        arrow
                                                                                    >
                                                                                        <Typography variant="subtitle2">
                                                                                            {
                                                                                                childTransaction.displayAmountValue
                                                                                            }
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}
                                        {updatedTransaction.id ===
                                            transaction.id && (
                                            <TransactionForm
                                                isLoading={isLoading}
                                                formErrors={formErrors}
                                                transaction={
                                                    transaction.id ===
                                                    updatedTransaction.id
                                                        ? updatedTransaction
                                                        : transaction.toInputGroupedTransaction()
                                                }
                                                accounts={accounts}
                                                onChange={onUpdateTransaction}
                                            />
                                        )}
                                    </AccordionDetails>
                                    {expanded === transaction.id && <Divider />}
                                    <AccordionActions>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{marginRight: 'auto'}}
                                            startIcon={<DeleteIcon />}
                                            size="small"
                                            onClick={() => handleDelete(transaction.id)}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        {updatedTransaction.id ===
                                            transaction.id && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                disabled={isLoading}
                                                onClick={onTransactionUpdate}
                                            >
                                                {isLoading && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={
                                                            classes.loadingSpinner
                                                        }
                                                    />
                                                )}
                                                Update
                                            </Button>
                                        )}
                                        {updatedTransaction.id !==
                                            transaction.id && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                disabled={isLoading}
                                                onClick={(
                                                    event: MouseEvent
                                                ) => {
                                                    event.preventDefault();
                                                    setUpdatedTransaction(
                                                        transaction.toInputGroupedTransaction()
                                                    );
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </AccordionActions>
                                </Accordion>
                            );
                        }
                    )}
                {!isLoadingTransactions && groupedTransactions.length > 0 && (
                    <TablePagination
                        component="div"
                        count={-1}
                        page={paginationOptions.page}
                        labelRowsPerPage="Transactions per page"
                        onChangePage={(
                            event: MouseEvent<HTMLButtonElement> | null,
                            page: number
                        ) => {
                            if (event) {
                                event.preventDefault();
                            }

                            const newState = {
                                ...paginationOptions,
                                page,
                            };

                            setPaginationOptions(newState);

                            loadTransactions(
                                organisationId as string,
                                newState
                            );
                        }}
                        rowsPerPage={paginationOptions.countPerPage}
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        onChangeRowsPerPage={(
                            event: ChangeEvent<HTMLInputElement>
                        ) => {
                            event.preventDefault();

                            const newState = {
                                ...paginationOptions,
                                countPerPage: parseInt(event.target.value),
                            };

                            setPaginationOptions(newState);

                            loadTransactions(
                                organisationId as string,
                                newState
                            );
                        }}
                    />
                )}
            </Container>
        </AppLayout>
    );
}
