import React, { ChangeEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CONSTANTS from 'consts';
import OrganisationApi from '../services/apis/OrganisationApi';
import { captureSentryError } from '../plugins/sentry';
import ErrorHandler from '../services/errors';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import Organisation, { SerializedOrganisation } from '../models/organisation';
import { Menu, MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    GlobalState,
    setNavOpen,
    setOrganisationId,
    setTimezone,
    setUser,
} from '../store';
import CircularProgress from '@material-ui/core/CircularProgress';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import { AccountBalanceWallet, AccountCircle } from '@material-ui/icons';
import BalanceIcon from './BalanceIcon';
import { createOrganisationRoute } from '../store/selectors';
import CurrencyIcon from './CurrencyIcon';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    bold: {
        fontWeight: 'bold',
        '& .MuiInputBase-input': {
            fontWeight: 'bold',
        },
    },
    toolbarIconContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        justifyContent: 'flex-end',
        ...theme.mixins.toolbar,
    },

    organisation: {
        flexGrow: 1,
    },
    toolbarIcon: {
        marginLeft: 'auto',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    menuButton: {
        marginRight: 36,
    },

    menuButtonHidden: {
        display: 'none',
    },

    title: {
        flexGrow: 1,
    },

    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },

    appBarSpacer: theme.mixins.toolbar,

    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },

    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    fixedHeight: {
        height: 240,
    },

    pt2: {
        paddingTop: theme.spacing(1),
        fontWeight: 'bold',
    },

    organisationBtn: {
        fontWeight: 'bold',
    },
}));

export interface AppLayoutProps {
    title: string;
    children?: React.ReactElement;
}

export default function AppLayout(props: AppLayoutProps) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [organisations, setOrganisations] = useState(
        [] as Array<Organisation>
    );
    const [organisationsLoading, setOrganisationsLoading] = useState(true);

    const history = useHistory();
    const location = useLocation();

    const globalState = useSelector((state: GlobalState) => state);

    const organisationId = useSelector(
        (state: GlobalState) => state.organisationId
    );

    const handleDrawerOpen = () => {
        dispatch(setNavOpen(true));
    };
    const handleDrawerClose = () => {
        dispatch(setNavOpen(false));
    };

    const selectedOrganisation = (): string => {
        if (organisationId) {
            return organisationId;
        }
        if (organisations.length > 0) {
            return organisations[0].id;
        }
        return '';
    };

    const loadOrganisations = () => {
        setOrganisationsLoading(true);
        OrganisationApi.index()
            .then((response: AxiosResponse) => {
                const orgs: Array<Organisation> = response.data.payload.map(
                    (organisation: SerializedOrganisation) => {
                        return new Organisation(organisation);
                    }
                );

                setOrganisations(orgs);
                if (orgs.length > 0 && !organisationId) {
                    dispatch(setOrganisationId(orgs[0].id));
                    dispatch(setTimezone(orgs[0].timezone));
                } else {
                    dispatch(
                        setTimezone(
                            orgs.find((org) => org.id === organisationId)
                                ?.timezone
                        )
                    );
                }

                setOrganisationsLoading(false);
            })
            .catch((error: Error) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
            });
    };

    const hasSelectedOrganisation: boolean =
        !!selectedOrganisation() && !!organisationId;

    const handleOrganisationChange = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        event.preventDefault();

        if (event.target.value === '') {
            return;
        }
        dispatch(setOrganisationId(event.target.value));
        dispatch(
            setTimezone(
                organisations.find((org) => org.id === event.target.value)
                    ?.timezone
            )
        );
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const accountOpen = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        dispatch(setUser(null));
        dispatch(setOrganisationId(null));
        dispatch(setTimezone(null));
        enqueueSnackbar('You have been successfully logged out!', {
            variant: 'info',
        });
        history.push(CONSTANTS.ROUTES.PATHS.AUTH.LOGIN);
    };

    useEffect(() => {
        loadOrganisations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(
                    classes.appBar,
                    globalState.navOpen && classes.appBarShift
                )}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            globalState.navOpen && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {props.title}
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={accountOpen}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !globalState.navOpen && classes.drawerPaperClose
                    ),
                }}
                open={globalState.navOpen}
            >
                <div className={classes.toolbarIconContainer}>
                    {organisationsLoading && (
                        <Button fullWidth>
                            <CircularProgress size={24} />
                        </Button>
                    )}
                    {!organisationsLoading && organisations.length === 0 && (
                        <Button
                            color="secondary"
                            onClick={() => {
                                history.push(
                                    CONSTANTS.ROUTES.PATHS.ORGANISATION.CREATE
                                );
                            }}
                        >
                            Add Organisation
                        </Button>
                    )}
                    {!organisationsLoading && organisations.length > 0 && (
                        <TextField
                            select
                            fullWidth
                            label="Organisation"
                            className={classes.bold}
                            variant="outlined"
                            value={selectedOrganisation()}
                            size="small"
                            onChange={handleOrganisationChange}
                        >
                            {organisations.map((option: Organisation) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                            <MenuItem value="">
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        history.push(
                                            CONSTANTS.ROUTES.PATHS.ORGANISATION
                                                .CREATE
                                        );
                                    }}
                                >
                                    Add Organisation
                                </Button>
                            </MenuItem>
                        </TextField>
                    )}
                    <IconButton
                        className={classes.toolbarIcon}
                        onClick={handleDrawerClose}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {hasSelectedOrganisation && (
                        <div>
                            <ListItem
                                button
                                selected={[
                                    createOrganisationRoute(
                                        globalState,
                                        CONSTANTS.ROUTES.PATHS.DASHBOARD
                                    ),
                                ].includes(location.pathname)}
                                onClick={() => {
                                    history.push(
                                        CONSTANTS.ROUTES.PATHS.DASHBOARD
                                    );
                                }}
                            >
                                <ListItemIcon>
                                    <MultilineChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem
                                button
                                selected={[
                                    createOrganisationRoute(
                                        globalState,
                                        CONSTANTS.ROUTES.PATHS.TRANSACTIONS
                                            .INDEX
                                    ),
                                ].includes(location.pathname)}
                                onClick={() => {
                                    history.push(
                                        createOrganisationRoute(
                                            globalState,
                                            CONSTANTS.ROUTES.PATHS.TRANSACTIONS
                                                .INDEX
                                        )
                                    );
                                }}
                            >
                                <ListItemIcon>
                                    <BalanceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Transactions" />
                            </ListItem>
                            <ListItem
                                selected={[
                                    createOrganisationRoute(
                                        globalState,
                                        CONSTANTS.ROUTES.PATHS.ACCOUNTS.INDEX
                                    ),
                                ].includes(location.pathname)}
                                button
                                onClick={() => {
                                    history.push(
                                        createOrganisationRoute(
                                            globalState,
                                            CONSTANTS.ROUTES.PATHS.ACCOUNTS
                                                .INDEX
                                        )
                                    );
                                }}
                            >
                                <ListItemIcon>
                                    <AccountBalanceWallet />
                                </ListItemIcon>
                                <ListItemText primary="Chart of Accounts" />
                            </ListItem>
                            <ListItem
                                selected={[
                                    createOrganisationRoute(
                                        globalState,
                                        CONSTANTS.ROUTES.PATHS.CURRENCY_PAIR
                                            .INDEX
                                    ),
                                ].includes(location.pathname)}
                                button
                                onClick={() => {
                                    history.push(
                                        createOrganisationRoute(
                                            globalState,
                                            CONSTANTS.ROUTES.PATHS.CURRENCY_PAIR
                                                .INDEX
                                        )
                                    );
                                }}
                            >
                                <ListItemIcon>
                                    <CurrencyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Currency" />
                            </ListItem>
                        </div>
                    )}
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {props.children}
            </main>
        </div>
    );
}
