export interface SerializedCurrency {
    name: string;
    symbol: string;
    in_cents: boolean;
}

export default class Currency {
    private readonly _name: string;
    private readonly _symbol: string;
    private readonly _inCents: boolean;

    constructor(currency: SerializedCurrency) {
        this._name = currency.name;
        this._symbol = currency.symbol;
        this._inCents = currency.in_cents;
    }

    get name(): string {
        return this._name;
    }

    get symbol(): string {
        return this._symbol;
    }

    get inCents(): boolean {
        return this._inCents;
    }

    serialize(): SerializedCurrency {
        return {
            name: this.name,
            symbol: this.symbol,
            in_cents: this.inCents,
        };
    }
}
