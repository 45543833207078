import React, { ChangeEvent } from 'react';
import { MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

export interface DirectionSelectorProps {
    disabled: boolean;
    error: boolean;
    helperText: string | undefined;
    value: string | null;
    label: string | undefined;
    onChange: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
}

export interface SelectItem {
    text: string;
    value: string;
}

export default function DirectionSelector(props: DirectionSelectorProps) {
    const options: Array<SelectItem> = [
        {
            text: 'Deposit',
            value: 'deposit',
        },
        {
            text: 'Withdrawal',
            value: 'withdrawal',
        },
    ];

    return (
        <TextField
            required
            fullWidth
            size="small"
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            select
            name="direction"
            margin="normal"
            key="direction"
            label={props.label ? props.label : 'Direction'}
            placeholder="Deposit or Withdrawal"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
        >
            {options.map((option: SelectItem) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
            ))}
        </TextField>
    );
}
