import Currency, { SerializedCurrency } from './currency';

export interface SerializedOrganisation {
    name: string;
    id: string;
    owner_id: string;
    updated_at: string;
    created_at: string;
    timezone: string;
    currency: SerializedCurrency;
}

export default class Organisation {
    private readonly _name: string;
    private readonly _id: string;
    private readonly _ownerId: string;
    private readonly _updatedAt: Date;
    private readonly _createdAt: Date;
    private readonly _currency: Currency;
    private readonly _timezone: string;

    constructor(organisation: SerializedOrganisation) {
        this._name = organisation.name;
        this._ownerId = organisation.owner_id;
        this._id = organisation.id;
        this._createdAt = new Date(organisation.created_at);
        this._updatedAt = new Date(organisation.updated_at);
        this._currency = new Currency(organisation.currency);
        this._timezone = organisation.timezone;
    }

    get timezone(): string {
        return this._timezone;
    }

    get currency(): Currency {
        return this._currency;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get ownerId(): string {
        return this._ownerId;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get createdAt(): Date {
        return this._createdAt;
    }
}
