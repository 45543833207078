import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { mdiCurrencySign } from '@mdi/js';

export default function CurrencyIcon() {
    return (
        <SvgIcon>
            <path d={mdiCurrencySign} />
        </SvgIcon>
    );
}
