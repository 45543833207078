export interface TablePaginationOptions {
    page: number;
    countPerPage: number;
}

export function MakeTablePaginationOptions(
    page: number = 0,
    countPerPage: number = 20
): TablePaginationOptions {
    return {
        page,
        countPerPage,
    };
}
